import { relativeTimeThreshold } from "moment"
import { IwtsSymbolDefs } from "./wts.ui.data"

const SESSION = ("___WTS-SESSION")

export function getDefaultSession(): Session {
    return (Window as any)[SESSION]
}

function setDefaultSession(value: Session) {
    (Window as any)[SESSION] = value
}

export function padStart(str: string, targetLength: number, padString: string) {
    targetLength = targetLength >> 0 //truncate if number, or convert non-number to 0;
    padString = String(typeof padString !== 'undefined' ? padString : ' ')
    if (str.length >= targetLength) {
        return String(str)
    } else {
        targetLength = targetLength - str.length
        if (targetLength > padString.length) {
            padString += padString.repeat(targetLength / padString.length) //append to original to ensure we are longer than needed
        }
        return padString.slice(0, targetLength) + String(str)
    }
};

export function padEnd(str: string, targetLength: number, padString: string): string {
    targetLength = targetLength >> 0 //floor if number or convert non-number to 0;
    padString = String((typeof padString !== 'undefined' ? padString : ' '))
    if (str.length > targetLength) {
        return String(str)
    }
    else {
        targetLength = targetLength - str.length
        if (targetLength > padString.length) {
            padString += padString.repeat(targetLength / padString.length) //append to original to ensure we are longer than needed
        }
        return String(str) + padString.slice(0, targetLength)
    }
};

function encodeTrans(value: string) {
    let trans = (value.indexOf(".") >= 0) ? encodeURIComponent(value.replace("/", "//")) : value

    const libName = trans.slice(0, Math.max(trans.indexOf("."), trans.indexOf("/"), 0))
    if (!libName.toLowerCase().startsWith("millenium") && libName !== "")
        trans = "millenium:" + trans

    return trans
}

type EventCallback = (args?: any) => void

export class OneEvent {
    private _callback: EventCallback
    constructor(callback: EventCallback) {
        this._callback = callback
    }
    trigger(args: any) { this._callback(args) }
}

export class EventEmitter {
    private _subscribers: (Function | OneEvent)[] = [];
    trigger(args: any = null) {
        var i = this._subscribers.length - 1
        while (i >= 0) {
            if (!this._subscribers[i]) {
                i--
            }
            else if (this._subscribers[i] instanceof OneEvent) {
                (this._subscribers[i] as OneEvent).trigger(args)
                this._subscribers.splice(i, 1)
                if (this._subscribers.length === 0) break

            } else {
                (this._subscribers[i] as Function)(args)
                i--
            }
        }
    }
    one(callback: EventCallback) { this._subscribers.push(new OneEvent(callback)) }
    subscribe(callback: EventCallback) { this._subscribers.push(callback) }
    unsubscribe(callback: EventCallback) {
        let i = this._subscribers.indexOf(callback)
        if (i >= 0) this._subscribers = this._subscribers.splice(i, 1)
    }
    assign(other: EventEmitter) {
        this._subscribers = other._subscribers
    }
}

export class Session {
    private _auth_fail = new EventEmitter();
    private _login_done = new EventEmitter();
    private _appName: string = "";
    private _userName: string = "";
    private _userGroup: string = "";
    private _id: string = "";
    private _loginData: any
    private _host = "";
    private _in_login = false;
    private _wsid = "";

    get onAuthFail() { return this._auth_fail };
    get onLoginDone() { return this._login_done };
    destroy() {
        this._auth_fail = new EventEmitter()
        this._login_done = new EventEmitter()
    }
    getLoggedUserInfo() {
        return (this.loginData) ? {
            groupName: this.loginData.group_name,
            employeeAccount: this.loginData.user_employee_account,
            employeeName: this.loginData.user_employee_name,
            employeeId: this.loginData.user_employee_id,
            employeeEmail: this.loginData.user_email
        } : {}
    }

    get userName(): string {
        return this._userName
    }

    get userGroup(): string {
        return this._userGroup
    }

    get wsid(): string {
        return this._wsid
    }

    get isDefault(): boolean {
        return this === getDefaultSession()
    }

    get loginData() {
        return this._loginData
    }

    get host() {
        return this._host
    }

    get id() {
        return this._id
    }

    get appName() {
        return this._appName
    }

    set isDefault(value: boolean) {
        if (!this._id) throw new Error("A session must be active to be set default")
        if (value) setDefaultSession(this)
    }

    get inLogin() { return this._in_login }

    loginDone() {
        this._login_done.trigger(this)
        this._in_login = false
    }

    protected assignSessionData(data: any) {
        this._loginData = data
        this._id = data.session
        this._userName = data.user_name
        this._userGroup = data.group_name
        this._wsid = data.wsid
    }

    async login(appName: string, host: string, username: string, password: string) {
        if (host && !this._host) this._host = host
        this._appName = appName
        this._in_login = true
        this._loginData = null
        this._id = ""

        let data: any

        try {
            data = await Client.http<any>(this._host + "/api/login",
                "GET",
                "",
                "",
                true,
                this,
                new Map([
                    ["Content-Type", "application/json"],
                    ["WTS-Authorization", username + '/' + password],
                    ["WTS-LicenceType", appName],
                    ["WTS-AppName", 'millenium']
                ]),
                true
            )
        } finally {
            this._in_login = false
        }

        this.assignSessionData(data)
        this.loginDone()

        return data
    }

    async logout(ignoreServer: boolean = false) {
        try {
            await Client.http<any>(this._host + "/api/logout",
                "GET",
                "",
                "",
                true,
                this
            )
        } catch (e) {
            if (!ignoreServer) throw e
        }
        this._loginData = null
        this._id = ""
    }

    isActive() {
        return !!this._id
    }

    async joinSession(host: string, sessionId: string, sessionData: any, joinOnServer: boolean, appName?: string) {
        const sessionParts = sessionId && sessionId.split("|")
        sessionId = (sessionParts.length === 1) ? sessionId : (sessionParts[0] + "|" + sessionParts[1])
        this._appName = appName || ""
        this._host = host
        this._in_login = true
        if (joinOnServer) {
            this._loginData = sessionData
            this._id = sessionData.session || sessionId

            let loginData: any
            try {
                loginData = await Client.http<any>(this._host + "/api/login",
                    "GET",
                    "",
                    "",
                    true,
                    this,
                    new Map([
                        ["Content-Type", "application/json"],
                        ["WTS-Token", (sessionParts.length > 2 ? (sessionParts[2]?.replace("/", "\\") || "") + "/" + (sessionParts[3] || "") : "")]
                    ])
                )
            } finally {
                this._in_login = false
            }

            this._loginData = loginData
            this._userName = this._loginData.user_name
            this._userGroup = this._loginData.group_name
            this._wsid = this.loginData.wsid ?? sessionParts[0]
            this.loginDone()
            return this._loginData
        }
        else {
            this._userName = sessionParts[2].split("\\")[1]
            this._userGroup = sessionParts[2].split("\\")[0]
            this._wsid = sessionParts[0]
            this._loginData = sessionData
            this._id = sessionData.session || sessionId
            this.loginDone()
            return Promise.resolve()
        }
    }
}

if (!getDefaultSession()) {
    setDefaultSession(new Session())
}

export class Client {

    private _session: Session
    static get defaultSession() {
        return getDefaultSession()
    }

    constructor(session?: Session) {
        this._session = session || getDefaultSession()
    }

    static errorDescription(status: number, statusText: string) {
        if (status === 0)
            return "Sem conexão com servidor"
        else
            return statusText
    }

    static get onLine() {
        //return navigator.onLine;
        return true
    }

    getJSON<T>(transaction: string, params = {}): Promise<T> {
        return Client.getJSON<T>(transaction, params, this._session) as any
    }

    static getJSON<T>(transaction: string, params = {}, session = getDefaultSession()): Promise<T> {
        return Client.get(transaction, params, { raw: true, neverPost: true }, session) as any
    }

    static getText(transaction: string, params?: any): Promise<string> {
        return this.http(transaction, "GET", params && JSON.stringify(params), "", true, getDefaultSession())
    }

    static getBlob(url: string, params?: any): Promise<Blob> {
        return this.http(url, params ? "POST" : "GET", params && JSON.stringify(params), "blob", !params, getDefaultSession())
    }

    get<T>(transaction: string, params = {}, options: { cache?: boolean, neverPost?: boolean } | null = null) {
        return Client.get<T>(transaction, params, options, this._session)
    }

    static getMetadata(transaction: string, params?: any) {
        return Client.get<any>(transaction, params, { modifier: (transaction.indexOf("?") === -1) ? "/$metadata" : "&$metadata=only" }) as Promise<any>
    }

    getMetadata(transaction: string, params?: any) {
        return Client.getMetadata(transaction, params)
    }

    private static httpXHR<T>(url: string, method: "GET" | "POST", body: string | Blob, responseType: XMLHttpRequestResponseType = "", neverPost = false, session?: Session, headers?: Map<string, string>, anonymous = false, timeout = 0): Promise<T> {
        return new Promise((resolve, reject) => {

            if (!anonymous && (!session || session.id === "" || session.id === undefined))
                return reject("Sem sessão com o servidor")

            let xhr = new XMLHttpRequest()

            try {
                xhr.open(neverPost ? "GET" : "POST", url, true)
            } catch {
                reject("Sem conexão com o servidor")
            }

            const defaultHeaders = new Map([
                ["Content-Type", "application/json"],
                ["X-DateFormat", "ISOTZ"],
                ["X-HTTP-Method", method],
                ["WTS-Session", session?.id],
                ["X-IdentifierCase", method === "GET" ? "upper" : ""]
            ])

            if (headers?.has("Authorization"))
                defaultHeaders.clear()

            headers?.forEach((value, key) => defaultHeaders.set(key, value))

            defaultHeaders.forEach((value, key) => xhr.setRequestHeader(key, value || ""))

            xhr.timeout = timeout
            xhr.responseType = responseType

            xhr.onerror = (ev) => {
                if (xhr.status === 0) reject("Sem conexão")
            }

            xhr.onreadystatechange = async () => {//Call a function when the state changes.
                if (xhr.readyState == XMLHttpRequest.DONE) {
                    // Request finished. Do processing here.
                    if (xhr.status >= 400) {
                        if (xhr.status === 401 && !session?.inLogin) {
                            //here we will wait for the login to be done
                            //then we will resolve with another get
                            session?.onLoginDone.one(_ => {
                                resolve((Client.httpXHR as any).apply(undefined, arguments))
                            })
                            session?.onAuthFail.trigger()
                        }
                        else {

                            const responseText = responseType === "blob" ? await xhr.response.text?.() : xhr.responseText

                            try {
                                var errorObj = (responseText && responseText.length > 0) ? JSON.parse(responseText) : null
                            } catch {
                                errorObj = null
                            }
                            if (errorObj && errorObj.error && errorObj.error.message && errorObj.error.message.value)
                                reject(errorObj.error.message.value)
                            else
                                reject(this.errorDescription(xhr.status, xhr.statusText))

                        }
                    }
                    else if (xhr.status == 0)
                        reject(this.errorDescription(0, "invalid call"))
                    else
                        if (responseType === "blob") {
                            resolve(xhr.response)
                        } else
                            try {
                                try {
                                    resolve(JSON.parse(xhr.responseText))
                                } catch {
                                    resolve(xhr.responseText as any)
                                }
                            } catch (e) {
                                reject(e)
                            }
                }
            }

            try {
                //if (!!body) xhr.send(body);
                xhr.send(body)
            } catch (e) {
                reject("Sem conexão com o servidor " + e)
            }
        })
    }

    public static http<T>(url: string, method: "GET" | "POST", body: string | Blob, responseType: "" | "blob", neverPost = false, session?: Session, headers?: Map<string, string>, anonymous = false, timeout = 0): Promise<T> {
        return this.httpXHR(url, method, body, responseType, neverPost, session, headers, anonymous, timeout)
    }

    private static httpFetch<T>(url: string, method: "GET" | "POST", body: string, neverPost = false, session = getDefaultSession()): Promise<T> {
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: neverPost ? "GET" : "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-DateFormat": "ISOTZ",
                    "X-HTTP-Method": method,
                    "WTS-Session": session.id,
                    "X-IdentifierCase": method === "GET" ? "upper" : ""
                },
                body
            })
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 401) {
                            //here we will wait for the login to be done
                            //then we will resolve with another get
                            session.onLoginDone.one(_ => {
                                resolve((this.http as Function).call(undefined, arguments))
                            })
                            session.onAuthFail.trigger()
                        }
                        else {
                            response.text().then(responseText => {
                                try {
                                    var errorObj = (responseText && responseText.length > 0) ? JSON.parse(responseText) : null
                                } catch {
                                    errorObj = null
                                }
                                if (errorObj && errorObj.error && errorObj.error.message && errorObj.error.message.value)
                                    reject(errorObj.error.message.value)
                                else
                                    reject(this.errorDescription(response.status, response.statusText))
                            })
                        }
                    }
                    else
                        resolve(response.json())
                })
                .catch(_ => {
                    reject("Sem conexão com o servidor")
                })
        })
    }

    static get<T>(transaction: string, params?: any, options: { casing?: "none" | "upper" | "lower", cache?: boolean, neverPost?: boolean, raw?: boolean, modifier?: string, anonymous?: boolean, licenceType?: string, tags?: { [key: string]: string } } | null = null, session: Session | null = null): Promise<{ value: T[], "odata.metadata"?: IwtsSymbolDefs }> {

        if (!session) session = getDefaultSession()

        let paramStr = ""
        let transactionStr = (options?.raw) ? transaction : (session.host + "/api/" + encodeTrans(transaction))
        let e

        if (options && options.neverPost) {
            for (e in params)
                if (params.hasOwnProperty(e))
                    paramStr = paramStr + e + "=" + (params as any)[e] + "&"
        }

        if (paramStr.length > 0)
            if (options?.raw)
                transactionStr = transactionStr + "?" + encodeURIComponent(paramStr.substr(0, paramStr.length - 1))
            else
                transactionStr = transactionStr + "?" + encodeURI(paramStr.substr(0, paramStr.length - 1))

        const header = new Map<string, string>()
        if (options?.tags) {
            for (const key in options.tags)
                header.set("WTS-TAG-" + key, options.tags[key])
        }

        if (options?.licenceType)
            header.set("WTS-LicenceType", options?.licenceType)

        if (options?.casing)
            header.set("X-IdentifierCase", options?.casing)

        return this.http(
            (options && options.raw) ? transactionStr : (transactionStr + (options?.modifier || "")),
            "GET",
            (options && options.neverPost || !params) ? "" : JSON.stringify(params),
            "",
            (options && options.neverPost) || false,
            options?.anonymous ? undefined : session,
            header,
            options?.anonymous
        )

    }

    post<T>(transaction: string, params: string | Object | Blob = ""): Promise<T> {
        return Client.post<T>(transaction, params)
    }

    static postBlob(url: string, blob: Blob) {
        return this.http(
            url,
            "POST",
            blob,
            "",
            false,
            getDefaultSession()
        )
    }

    static post<T>(transaction: string, params: string | Object | Blob = "", session: Session | null = null, headers?: Map<string, string>, timeout = 0): Promise<T> {
        if (!session) session = getDefaultSession()
        return this.http(
            transaction.startsWith("http") ? transaction : session.host + "/api/" + encodeTrans(transaction),
            "POST",
            JSON.stringify(params),
            "",
            false,
            session,
            headers,
            false,
            timeout
        )
    }
}
