import { IModules, INotifiers } from "lib/millennium.core";
import { Client } from "lib/wts.client";

export const modules: IModules = {
  "dataform": {
    instance: () => import("./pages/millennium.dataform/millennium.dataform"),
    css: () => import("./pages/millennium.dataform/millennium.dataform.scss"),
    html: () => import("./pages/millennium.dataform/millennium.dataform.html")
  },
  "dataview": {
    instance: () => import("./pages/millennium.dataview/millennium.dataview"),
    css: () => import("./pages/millennium.dataview/millennium.dataview.scss"),
    html: () => import("./pages/millennium.dataview/millennium.dataview.html")
  },
  "portal": {
    instance: () => import("./pages/millennium.portal/millennium.portal"),
    css: () => import("./pages/millennium.portal/millennium.portal.scss"),
    html: () => import("./pages/millennium.portal/millennium.portal.html")
  },
  "playground": {
    instance: () => import("./pages/playground/playground"),
    css: () => import("./pages/playground/playground.scss"),
    html: () => import("./pages/playground/playground.html")
  },
  "reports": {
    instance: () => import("./pages/millennium.reports/millennium.reports"),
    css: () => import("./pages/millennium.reports/millennium.reports.scss"),
    html: () => import("./pages/millennium.reports/millennium.reports.html")
  },
  "reports_catalog": {
    instance: () => import("./pages/millennium.reports/millennium.reports.catalog"),
    css: () => import("./pages/millennium.reports/millennium.reports.catalog.scss"),
    html: () => import("./pages/millennium.reports/millennium.reports.catalog.html")
  },
  "configuration": {
    instance: () => import("./pages/millennium.configuration/millennium.configuration"),
    css: () => import("./pages/millennium.configuration/millennium.configuration.scss"),
    html: () => import("./pages/millennium.configuration/millennium.configuration.html")
  }
};

const es6module = "es6loader.js";
let es6loader: any;

const moduleNames: { name: string }[] = [];
const moduleInstances = new WeakMap<{ name: string }, any>();

function importModule(fileName: string, moduleName?: string) {

  let nameObj = moduleNames.find(e => e.name === fileName)!;

  if (nameObj) {
    const instance = moduleInstances.get(nameObj);
    if (instance) {
      if (typeof instance === "string" || instance instanceof Error)
        throw instance;

      return instance
    };
  } else {
    nameObj = { name: fileName };
    moduleNames.push(nameObj);
  }

  return new Promise<any>((resolve, reject) => {
    (window as any).exports = {};
    const script = document.createElement("script");
    script.src = fileName;
    script.onload = function (e) {
      if (!moduleName) {
        moduleName = fileName.slice(fileName.lastIndexOf("/") + 1);
        moduleName = moduleName.slice(0, moduleName.lastIndexOf("."));
        if (moduleName.endsWith(".min"))
          moduleName = moduleName.slice(0, moduleName.lastIndexOf("."));
      }

      const instance = (moduleName && (window as any)[moduleName]) || (window as any).exports;
      moduleInstances.set(nameObj, instance);
      resolve(instance);
    }
    script.onerror = e => {
      const errorStr = "Erro carregando módulo " + nameObj.name;
      moduleInstances.set(nameObj, errorStr);
      reject(errorStr);
    }
    document.head.append(script);
  })
}

export async function loadExtension(name: string): Promise<any> {
  if (name.endsWith(".mjs")) {
    if (!es6loader) es6loader = (await importModule(es6module));
    return es6loader.importES6Module(name);
  }
  else {
    return importModule(name);
  }
}

export async function loadModule(
  name: string
): Promise<{ moduleClass: any; htmlTemplate: string; cssTemplate: string }> {

  let module = modules[name];

  //dynamic module
  if (!module) {
    const libPos = name.indexOf(".");
    const libName = name.slice(0, libPos);
    name = name.slice(libPos + 1);
    try {
      const moduleLib = await importModule(libName + "/" + libName + ".js", libName);
      module = moduleLib.default[name];
    } catch {
      throw Error("Library " + name + " not found");
    }

    if (!module)
      throw Error("Module " + name + " not found in library " + libName)
  }

  return Promise.all([
    module.instance(),
    module.css(),
    module.html()
  ]).then(all => ({
    moduleClass: (all[0] && all[0].default) || all[0] || "",
    cssTemplate: (all[1] && all[1].default) || all[1] || "",
    htmlTemplate: (all[2] && all[2].default) || all[2] || ""
  }));
}

export async function loadNotifiers(): Promise<INotifiers> {
  const notifiers: any[] = [];
  const json = await Client.getJSON<{ module: string }[]>("./millennium.notifiers.json");
  for (const notifierDef of json) {
    const notifierModule = await loadExtension(notifierDef.module);
    notifiers.push(notifierModule);
  }
  return notifiers;
}
