
(function (factory) {
  if (typeof define === 'function' && define.amd) {
    // [1] AMD anonymous module
    define(['exports', 'tabbable'], factory);
  } else if (typeof exports === 'object') {
    // [2] commonJS
    factory(exports, require('tabbable'));
  } else {
    // [3] No module loader (plain <script> tag) - put directly in global namespace
    factory(window, Tabbable);
  }
})(function (exports, tabbable) {

  var listeningFocusTrap = null;

  function focusTrap(element, userOptions) {
    var tabbableNodes = [];
    var nodeFocusedBeforeActivation = null;
    var active = false;
    var paused = false;

    var container = (typeof element === 'string')
      ? document.querySelector(element)
      : element;

    var config = userOptions || {};
    config.returnFocusOnDeactivate = (userOptions && userOptions.returnFocusOnDeactivate !== undefined)
      ? userOptions.returnFocusOnDeactivate
      : true;
    config.escapeDeactivates = (userOptions && userOptions.escapeDeactivates !== undefined)
      ? userOptions.escapeDeactivates
      : true;

    var trap = {
      activate: activate,
      deactivate: deactivate,
      pause: pause,
      unpause: unpause,
    };

    return trap;

    function activate(activateOptions) {
      if (active) return;

      var defaultedActivateOptions = {
        onActivate: (activateOptions && activateOptions.onActivate !== undefined)
          ? activateOptions.onActivate
          : config.onActivate,
      };

      active = true;
      paused = false;
      nodeFocusedBeforeActivation = document.activeElement;

      if (defaultedActivateOptions.onActivate) {
        defaultedActivateOptions.onActivate();
      }

      addListeners();
      return trap;
    }

    function deactivate(deactivateOptions) {
      if (!active) return;

      var defaultedDeactivateOptions = {
        returnFocus: (deactivateOptions && deactivateOptions.returnFocus !== undefined)
          ? deactivateOptions.returnFocus
          : config.returnFocusOnDeactivate,
        onDeactivate: (deactivateOptions && deactivateOptions.onDeactivate !== undefined)
          ? deactivateOptions.onDeactivate
          : config.onDeactivate,
      };

      removeListeners();

      if (defaultedDeactivateOptions.onDeactivate) {
        defaultedDeactivateOptions.onDeactivate();
      }

      if (defaultedDeactivateOptions.returnFocus) {
        setTimeout(function () {
          tryFocus(nodeFocusedBeforeActivation);
        }, 0);
      }

      active = false;
      paused = false;
      return this;
    }

    function pause() {
      if (paused || !active) return;
      paused = true;
      removeListeners();
    }

    function unpause() {
      if (!paused || !active) return;
      paused = false;
      addListeners();
    }

    function addListeners() {
      if (!active) return;

      // There can be only one listening focus trap at a time
      if (listeningFocusTrap) {
        listeningFocusTrap.pause();
      }
      listeningFocusTrap = trap;

      updateTabbableNodes();
      tryFocus(firstFocusNode());
      document.addEventListener('focus', checkFocus, true);
      document.addEventListener('click', checkClick, true);
      document.addEventListener('mousedown', checkPointerDown, true);
      document.addEventListener('touchstart', checkPointerDown, true);
      document.addEventListener('keydown', checkKey, true);

      return trap;
    }

    function removeListeners() {
      if (!active || listeningFocusTrap !== trap) return;

      document.removeEventListener('focus', checkFocus, true);
      document.removeEventListener('click', checkClick, true);
      document.removeEventListener('mousedown', checkPointerDown, true);
      document.removeEventListener('touchstart', checkPointerDown, true);
      document.removeEventListener('keydown', checkKey, true);

      listeningFocusTrap = null;

      return trap;
    }

    function getNodeForOption(optionName) {
      var optionValue = config[optionName];
      var node = optionValue;
      if (!optionValue) {
        return null;
      }
      if (typeof optionValue === 'string') {
        node = document.querySelector(optionValue);
        if (!node) {
          throw new Error('`' + optionName + '` refers to no known node');
        }
      }
      if (typeof optionValue === 'function') {
        node = optionValue();
        if (!node) {
          throw new Error('`' + optionName + '` did not return a node');
        }
      }
      return node;
    }

    function firstFocusNode() {
      var node;
      if (getNodeForOption('initialFocus') !== null) {
        node = getNodeForOption('initialFocus');
      } else if (container.contains(document.activeElement)) {
        node = document.activeElement;
      } else {
        node = tabbableNodes[0] || getNodeForOption('fallbackFocus');
      }

      if (!node) {
        throw new Error('You can\'t have a focus-trap without at least one focusable element');
      }

      return node;
    }

    // This needs to be done on mousedown and touchstart instead of click
    // so that it precedes the focus event
    function checkPointerDown(e) {
      if (config.clickOutsideDeactivates && !container.contains(e.target)) {
        deactivate({ returnFocus: false });
      }
    }

    function checkClick(e) {
      if (config.clickOutsideDeactivates) return;
      if (container.contains(e.target)) return;
      e.preventDefault();
      e.stopImmediatePropagation();
    }

    function checkFocus(e) {
      if (container.contains(e.target)) return;
      e.preventDefault();
      e.stopImmediatePropagation();
      // Checking for a blur method here resolves a Firefox issue (#15)
      if (typeof e.target.blur === 'function') e.target.blur();
    }

    function checkKey(e) {
      if (e.key === 'Tab' || e.keyCode === 9) {
        handleTab(e);
      }

      if (config.escapeDeactivates !== false && isEscapeEvent(e)) {
        deactivate();
      }
    }

    function handleTab(e) {
      e.preventDefault();
      updateTabbableNodes();
      var currentFocusIndex = tabbableNodes.indexOf(e.target);
      var lastTabbableNode = tabbableNodes[tabbableNodes.length - 1];
      var firstTabbableNode = tabbableNodes[0];

      if (e.shiftKey) {
        if (e.target === firstTabbableNode || tabbableNodes.indexOf(e.target) === -1) {
          return tryFocus(lastTabbableNode);
        }
        return tryFocus(tabbableNodes[currentFocusIndex - 1]);
      }

      if (e.target === lastTabbableNode) return tryFocus(firstTabbableNode);

      tryFocus(tabbableNodes[currentFocusIndex + 1]);
    }

    function updateTabbableNodes() {
      tabbableNodes = tabbable.Tabbable(container);
    }
  }

  function isEscapeEvent(e) {
    return e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27;
  }

  function tryFocus(node) {
    if (!node || !node.focus) return;
    if (node === document.activeElement) return;

    node.focus();
    if (node.tagName.toLowerCase() === 'input') {
      node.select();
    }
  }

  exports[typeof window !== "undefined" ? "createFocusTrap" : "default"] = focusTrap;
});