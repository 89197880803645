
export type FeedBackData = { waitMessages?: string[], estimatedTime?: number, element?: HTMLElement }

export interface IModuleLoaderItem {
    instance: () => Promise<any>
    css: () => Promise<any>
    html: () => Promise<any>
}

export interface INotificationItem {
    key: any
    description: string
}

export interface INotificationSource {
    name: string
    subscribe(callback: (notifier: INotificationSource, result: INotificationItem[]) => void): void
    action(): void
}

export interface IModules {
    [name: string]: IModuleLoaderItem
}

export type INotifiers = { Notifier: INotificationSource }[]

export interface IEventEmitter {

}

export class LazyLoadFacet {
    public timer?: any
    public delay: number
    constructor(ADelay: number) {
        this.delay = ADelay
    }
}

export enum CommandFlags {
    objectAction = 1 << 0,
    defaultEdit = 2 << 0
}

export interface ICommand {
    tag: number,
    id: string,
    data: string,
    vars?: string[],
    varValues?: { [index: string]: any },
    caption: string,
    iconClass?: string,
    flags?: CommandFlags
    isDropDown?: boolean
};

export interface IMillenniumFrame {
    readonly element: HTMLElement
    readonly browser: IMillenniumBrowser
    setCaption(value: string): void
    setUrl(value: string): void
    navigate(data: string, vars: Object): void
    navigateSc(key: string, data: string, vars: Object, caption?: any): void
    close(): void
    activate(): void | Promise<void>
    activity<T extends PromiseLike<unknown>>(waiter: T, feedBackData?: FeedBackData): T
    lazyLoad(lazyFacet: LazyLoadFacet, callback: () => void): void
    notify(message: string, type?: 'warning' | 'danger' | 'success'): void
    requestFullScreen(): void
    accessPrompt(message: string, key: string): PromiseLike<boolean>
    hasAccess(key: string): Promise<boolean>
    loadExtension(name: string): Promise<any>
}

export interface IRoute {
    text: string
    target: string
    urlKey: string
    icon: string
    caption?: string
    params?: any[]
    key?: string
    nodes?: IRoute[]
}

export enum MsgBoxFlags {
    MB_OK = 0x00000000,
    MB_OKCANCEL = 0x00000001,
    MB_ABORTRETRYIGNORE = 0x00000002,
    MB_YESNOCANCEL = 0x00000003,
    MB_YESNO = 0x00000004,
    MB_RETRYCANCEL = 0x00000005,

    MB_ICONERROR = 0x00000010,
    MB_ICONQUESTION = 0x00000020,
    MB_ICONWARNING = 0x00000030,
    MB_ICONINFORMATION = 0x00000040,

    MB_DEFBUTTON1 = 0x00000000,
    MB_DEFBUTTON2 = 0x00000100,
    MB_DEFBUTTON3 = 0x00000200,
    MB_DEFBUTTON4 = 0x00000300
}

export enum MsgBoxResult {
    IDOK = 1,
    IDCANCEL = 2,
    IDABORT = 3,
    IDRETRY = 4,
    IDIGNORE = 5,
    IDYES = 6,
    IDNO = 7,
    IDCLOSE = 8
}

export interface IMillenniumNativeFrame {
    resize(left: number, top: number, width: number, height: number, visible: number): void
    navigate(place: string): void
    destroy(): void
}

export interface IModalWindow {
    show(): Promise<any>
    close(result: any): void
}

export interface IModalWindowCommand {
    caption: string
    id?: any
    callback?: () => void
}

export interface IModalWindowOptions {
    title: string
    width?: string
    height?: string
    minHeight?: string
    maxWidth?: string
    maxHeight?: string
    commands: IModalWindowCommand[]
}

export interface IMillenniumBrowser {
    readonly isEmbedded: boolean
    readonly userName: string
    readonly userGroup: string
    readonly wsid: string
    readonly inLogin: boolean
    joinSession(host: string, sessionId: string, sessionData: any, joinOnServer: boolean, appName?: string): PromiseLike<void>
    load(frame: IMillenniumFrame, routeKey: string | undefined, routeObj: any, vars?: any): PromiseLike<void>
    getRoutes(): PromiseLike<IRoute[]>
    getRoute(key: string): PromiseLike<IRoute>
    notify(message: string | Error, type?: 'warning' | 'danger' | 'success'): void
    login(appname: string, host: string, userName: string, passWord: string): PromiseLike<any>
    switchUser(): Promise<void>
    accessPrompt(message: string, key: string): PromiseLike<boolean>
    hasAccess(key: string): Promise<boolean>
    logout(): PromiseLike<void>
    getSessionId(): Promise<string>
    navigate(place: string, vars: Object): Promise<void>
    navigateSc(key: string, data: string, vars: Object, caption?: any): Promise<void>
    getData(dataType: string, callback: (data: string) => void): void
    getVersion(): PromiseLike<{ [moduleName: string]: string | undefined }>
    getObjectActions(objectName: string): PromiseLike<ICommand[]>
    loadExtension(module: string): Promise<any>
    broadcast(subject: string, data: any): void
    subscribeAdd(subject: string, callback: (data: any) => void): void
    subscribeRemove(subject: string, callback: (data: any) => void): void
    printPDF(pdfAsString: string, printerName?: string, title?: string): void
    msgBox(message: string, title: string, flags: MsgBoxFlags): Promise<MsgBoxResult>
    createFrame(): IMillenniumFrame
    createFrameAsNative(): Promise<IMillenniumNativeFrame>
    createModal(content: HTMLElement, options: IModalWindowOptions): IModalWindow
}

export interface IMillennium {
    browser: IMillenniumBrowser
    lib: {
        "wts.ui.controls": Promise<typeof import("lib/wts.ui.controls")>
        "wts.ui.templating": Promise<typeof import("lib/wts.ui.templating")>
        "wts.ui.overlays": Promise<typeof import("lib/wts.ui.overlays")>
    }
    params: Map<string, string>
}

declare global {
    const millennium: IMillennium
}
