
import * as __FocusTrap from 'focus-trap'
import "./wts.ui.overlays.scss"

//global stack, so if used in diferent modules, it wll still work
const DROP_DOWN_STACK = "___DROPDOWN_STACK"
const FOCUS_TRAP = "___FOCUS_TRAP"

if (!(window as any)[DROP_DOWN_STACK])
    (window as any)[DROP_DOWN_STACK] = []

if (!(window as any)[FOCUS_TRAP])
    (window as any)[FOCUS_TRAP] = __FocusTrap

const focusTrap = (window as any)[FOCUS_TRAP]

const dropDownStack: BaseDropDown[] = (window as any)[DROP_DOWN_STACK]

function offset(element: HTMLElement) {
    if (!element.getClientRects().length)
        return { top: 0, left: 0 }

    const rect = element.getBoundingClientRect()
    const win = element.ownerDocument.defaultView

    return {
        top: rect.top + (win?.scrollY ?? 0),
        left: rect.left + (win?.scrollX ?? 0),
    }
}

export function isDropDownChild(element: HTMLElement | undefined) {

    if (!element) return false

    let p: HTMLElement | null | undefined = element
    while (p) {
        if (p.classList.contains("dropdown")) {
            return true
        };
        p = p.parentElement
    }
}

function handleLockMouseWheel(e: WheelEvent) {
    try {
        e.preventDefault()
    } catch { }
    e.stopPropagation()
}

function handleDropDownStackClick(e: MouseEvent) {
    if (!e.target) return

    //finds the first drop down parent of clicked element
    let targetIdx = -1
    let p: HTMLElement | null = e.target as HTMLElement
    while (p !== document.body && p) { //the last element must be body, otherwise the node is dettached from the DOM
        if (p.classList.contains("dropdown-menu")) //external dropdowns - do nothing
            return

        if (p.classList.contains("dropdown")) {
            targetIdx = dropDownStack.findIndex(e => (e.element === p) || (e.element?.firstElementChild === p))
            break
        };
        p = p.parentElement
    }

    //closes the next drop down over the found one
    //as each drop down closes the next, it will
    //cause all drop downs over the one found to close
    //important: if p is null here, the node is dettached and must not be considered
    if (p && targetIdx < dropDownStack.length - 1) {
        if (targetIdx === -1)
            dropDownStack[dropDownStack.length - 1].close()
        else
            dropDownStack[targetIdx + 1].close()
        e.preventDefault()
        e.stopImmediatePropagation()
    }
}

function handleWindowKeyDown(e: KeyboardEvent) {
    if (e.key === "Escape") {
        if (dropDownStack.length) {
            dropDownStack[dropDownStack.length - 1].close()
            e.preventDefault()
            e.stopImmediatePropagation()
        }
    }
}

window.addEventListener("keydown", handleWindowKeyDown)

function cvMeasure(value: string | number) {
    return typeof value === "number" ? `${value}px` : String(!isNaN(parseFloat(value)) ? `${value}px` : value)
}

function cvNumber(value: string | number): number {
    return typeof value === "number" ? value : (!isNaN(parseFloat(value)) ? parseFloat(value) : 0)
}

export type DropDownCallback = ((e: { open: boolean, element: HTMLElement }) => Promise<void> | void)
export type DropDownPosition = HTMLElement
    | { refElement?: HTMLElement, x?: number | string, y?: number | string, width?: number | string, height?: number | string, overflow?: string }

export class BaseDropDown {
    public _id = dialogId++;
    private _element?: HTMLElement
    private _lockedWheelList: HTMLElement[] = [];
    protected dropDownIndex?: number
    protected createElement(): HTMLElement {
        const element = document.createElement("ul")
        element.className = "dropdown"
        element.style.height = "200px"
        element.style.overflow = (!(this._position instanceof HTMLElement) ? this._position?.overflow : "") || "auto"
        element.style.position = "absolute"
        element.style.opacity = "0"
        element.style.zIndex = "100000"
        return element
    }
    private _position?: DropDownPosition
    get refElement() {
        return this._position instanceof HTMLElement ? this._position : this._position?.refElement
    }
    protected attachElement() {
        if (!this._element) return
        document.body.appendChild(this._element)
    }
    protected showElement() {
        if (!this._element) return
        this._element.style.opacity = "1"
        this._element.classList.toggle("open")
    }
    public get element() {
        return this._element
    }
    isOpen() {
        return this._element?.classList.contains("open")
    }
    protected async open(refElement?: DropDownPosition): Promise<void> {
        this._position = refElement
        return new Promise((resolve, reject) => {
            //this is necessary to avoid opening the dropdown --while-- 
            //the mousedown event is bubbling. when it happens, 
            //the handleDropDownStackClick is immediately called and the 
            //dropdown closes without showing up
            requestAnimationFrame(() => requestAnimationFrame(async () => {
                try {
                    await this.internalOpen(refElement)
                    resolve()
                } catch (err) {
                    reject(err)
                }
            }
            ))
        })
    }
    protected async internalOpen(position?: DropDownPosition): Promise<void> {

        let rect: { left: any, top: any, width: any, height: any, refWidth: any, refHeight: any } | undefined

        if (position) {
            const refElement = position instanceof HTMLElement ? position : position.refElement
            const refRect = position instanceof HTMLElement ? { x: "", y: "", width: "", height: "" } : position
            const elOffset = refElement ? offset(refElement) : { left: undefined, top: undefined }
            rect = {
                left: refRect.x || elOffset?.left || 0,
                top: refRect.y || ((elOffset?.top ?? 0) + (refElement?.offsetHeight ?? 0)) || 0,
                width: refRect.width || Math.max(200, refElement?.offsetWidth ?? 0),
                height: refRect.height,
                refWidth: refElement?.offsetWidth,
                refHeight: refElement?.offsetHeight,
            }
        }

        if (!this.isOpen()) {

            dropDownStack.push(this)
            this.dropDownIndex = this._id

            const el = this._element = this.createElement()

            let openPromise: Promise<void> | void = undefined
            if (this.onDropDown)
                openPromise = this.onDropDown({ open: true, element: el })

            if (openPromise) await openPromise

            //need to attach, so we can calculate dimensions
            this.attachElement()

            if (rect && position) {
                el.style.width = `${rect.width}px`

                let bottom: string | number = "auto"
                let top: string | number = rect.top
                let height = rect.height || el.style.height
                let width: string | number = rect.width
                let right: string | number = "auto"
                let left: string | number = rect.left

                const applySize = () => {
                    el.style.left = cvMeasure(left)
                    el.style.top = cvMeasure(top)
                    el.style.bottom = cvMeasure(bottom)
                    el.style.height = cvMeasure(height)
                    el.style.right = cvMeasure(right)
                    el.style.width = cvMeasure(width)
                }

                applySize()

                if (Number(top) + (el.offsetHeight ?? 0) > window.innerHeight) {
                    if (top > window.innerHeight / 2) {
                        bottom = window.innerHeight - cvNumber(rect.top) + cvNumber(rect.refHeight)
                        if (Number(top) - (el.offsetHeight ?? 0) < 0)
                            top = 10
                        else
                            top = "auto"
                    }
                    else
                        bottom = 10

                    if (top !== "auto") {
                        el.style.height = "auto"
                        el.style.minHeight = "auto"
                    }
                    applySize()
                }

                if (Number(left) + (el.offsetWidth ?? 0) > window.innerWidth) {
                    right = window.innerWidth - (rect.left + (position instanceof HTMLElement ? position.offsetWidth : position.width))
                    left = "auto"
                    width = ""
                    applySize()
                }
            }
        }

        this.showElement()

        if (position instanceof HTMLElement && this._lockedWheelList.length === 0) {
            let parentEl = position.parentElement
            while (parentEl) {
                this._lockedWheelList.push(parentEl)
                parentEl.addEventListener("wheel", handleLockMouseWheel)
                parentEl = parentEl.parentElement
            }
        }

        if (dropDownStack.length === 1) {
            window.addEventListener("mousedown", handleDropDownStackClick)
        }
    }

    close(closeData: any = {}) {

        const idx = dropDownStack.indexOf(this)
        if (idx === -1) return

        dropDownStack.splice(idx, 1)

        if (idx >= 0 && idx <= dropDownStack.length - 1)
            dropDownStack[idx].close() //closes next popup if exists

        this.dropDownIndex = undefined

        if (this.isOpen()) {
            if (this._element) {
                this._element.style.display = "none"
                this._element.classList.toggle("open")
            }
            if (this.onDropDown) this.onDropDown({ open: false, ...closeData })
        }

        if (dropDownStack.length === 0)
            window.removeEventListener("mousedown", handleDropDownStackClick)

        if (this._element) {
            this._element.remove()
            this._element = undefined
        }

        for (const parentEl of this._lockedWheelList)
            parentEl.removeEventListener("wheel", handleLockMouseWheel)

        this._lockedWheelList = []
    }
    protected onDropDown?: DropDownCallback
    destroy() {
        this.close()
    }
}

export class DropDown extends BaseDropDown {
    constructor(public override onDropDown?: DropDownCallback) {
        super()
    }
    public override async open(refElement?: DropDownPosition): Promise<void> {
        return super.open(refElement)
    }
}

var poppingState: boolean
var handlePopStateInstalled = false
function handlePopState(event: PopStateEvent) {
    if (event.state && event.state.type === "dialog-open") {
        //when this handler is called, the state is the current state now,
        //so the data of the event is of the current dialog and we must close the next one.
        const dropdownIndex = dropDownStack.findIndex(e => e._id === event.state.dropDownIndex) + 1
        poppingState = true
        if (dropdownIndex < dropDownStack.length) dropDownStack[dropdownIndex].close()
        poppingState = false
    } if (event.state === null && dropDownStack.length)
        dropDownStack[0]?.close()
}

type DialogOptions = {
    fullScreen?: boolean
    parentElement?: HTMLElement
    width?: string
    height?: string
    minHeight?: string
    minWidth?: string
    hideBtnClose?: boolean
    closeCallback?: () => boolean | Promise<boolean>
}

let dialogId = 0

export class Dialog extends BaseDropDown {
    private _focusTrap: any
    protected _content?: HTMLElement
    private _parentElement?: HTMLElement
    private _closeCallback?: () => boolean | Promise<boolean>

    override createElement(): HTMLElement {
        return document.createElement("div")
    }
    override isOpen() {
        return this._content?.classList.contains("open")
    }
    protected override showElement() {
        requestAnimationFrame(_ => requestAnimationFrame(_ => {
            if (!this._content) return
            this._content.style.opacity = "1"
            this._content.classList.add("open")
        }))
    }
    protected override attachElement() {
        if (!this.element) return
        if (this._parentElement)
            this._parentElement.appendChild(this.element)
        else
            document.body.appendChild(this.element)
    }
    protected createBody(content: HTMLElement) {
        return content
    }
    async show(element: HTMLElement | (() => HTMLElement), options?: DialogOptions): Promise<any> {
        this._parentElement = options?.parentElement
        this._closeCallback = options?.closeCallback

        return new Promise(async (resolve, reject) => {

            let bodyElement: HTMLElement

            if (!(element instanceof HTMLElement))
                bodyElement = element()
            else
                bodyElement = element

            const sibling = bodyElement.nextSibling
            this.onDropDown = (e) => {
                if (e.open) {

                    e.element.style.position = "absolute"
                    e.element.style.left = "0"
                    e.element.style.top = "0"
                    e.element.style.right = "0"
                    e.element.style.bottom = "0"
                    e.element.style.zIndex = "1000"
                    e.element.style.display = "flex"
                    e.element.style.overflow = "hidden"

                    if (options?.fullScreen) {
                        this._content = e.element

                        this._content.className = "fullscreen-dialog dropdown will-change"
                        this._content.style.display = "flex"
                        this._content.style.alignItems = "stretch"
                        this._content.style.justifyItems = "stretch"
                    }
                    else {
                        e.element.style.background = "rgba(0,0,0,0.5)"
                        e.element.style.alignItems = "center"
                        e.element.style.justifyContent = "center"

                        this._content = document.createElement("div")

                        this._content.className = "dropdown-dialog dropdown animate-dialog will-change shadow-around-lar"
                        this._content.style.display = "flex"
                        this._content.style.alignItems = "stretch"
                        this._content.style.justifyItems = "stretch"
                        this._content.style.margin = "auto"
                        this._content.style.overflow = "hidden"
                        this._content.style.width = options?.width || "auto"
                        this._content.style.height = options?.height || "auto"
                        this._content.style.minHeight = options?.minHeight || ""
                        this._content.style.minWidth = options?.minWidth || ""
                        e.element.appendChild(this._content)
                    }

                    bodyElement.hidden = false
                    this.createBody(this._content).appendChild(bodyElement)

                    if (options?.hideBtnClose) {
                        const closeBtn = this._content.getElementsByTagName("button")[0]
                        if (closeBtn)
                            closeBtn.style.display = options.hideBtnClose ? "none" : ""
                    }

                    history.pushState({ type: "dialog-open", dropDownIndex: this._id }, document.title)

                    if (!handlePopStateInstalled) {
                        window.addEventListener("popstate", handlePopState)
                        handlePopStateInstalled = true
                    }

                    this._focusTrap = focusTrap.createFocusTrap(this._content, { fallbackFocus: this._content, clickOutsideDeactivates: true })
                    this._focusTrap.activate()
                }
                else {
                    if (!poppingState) history.back()
                    this._focusTrap?.deactivate()
                    this._focusTrap = undefined
                    this._content?.classList.remove("open")
                    resolve((e as any).result)
                    this._content = undefined
                    this.onDropDown = undefined
                    bodyElement.hidden = true
                    sibling && sibling.parentNode && sibling.parentNode.insertBefore(bodyElement, sibling)
                }
            }

            await this.open()
        })
    }
    override close(data?: any) {
        if (!this.isOpen()) return

        const callClose = async () => {
            if (await this._closeCallback?.() !== false)
                super.close({ result: data })
        }
        callClose()
    }
}

export class Menu extends BaseDropDown {
    protected override createElement() {
        const element = super.createElement()
        element.classList.add("menu-dropdown")
        element.style.height = "auto"
        element.style.maxHeight = "90vh"
        return element
    }
    private _index: number = -1;
    private handleMouseDown = (e: MouseEvent) => {
        if (!(e.target instanceof HTMLElement)) return
        this._index = Number(e.target.getAttribute("data-index") ?? -1)
        this.close()
        e.stopImmediatePropagation()
    }
    async show(refElement: DropDownPosition, items: string[]): Promise<number> {
        return new Promise(async (resolve) => {
            this.onDropDown = (e) => {
                if (e.open) {
                    e.element.addEventListener("mousedown", this.handleMouseDown)
                    let i = 0
                    for (const item of items) {
                        let menuItemEl: HTMLElement

                        menuItemEl = document.createElement("div") as HTMLDivElement
                        menuItemEl.innerHTML = item === "-" ? "" : item

                        menuItemEl.className = item === "-" ? "toolbar-separator" : "toolbar-text"

                        menuItemEl.setAttribute("data-index", (i++).toString())
                        e.element.appendChild(menuItemEl)
                    }
                } else {
                    this.onDropDown = undefined
                    resolve(this._index)
                }
            }
            this.open(refElement)
        })
    }
}

export class DialogWindow extends Dialog {

    private _title?: string = ""
    private _titleBar?: HTMLElement

    private handleCloseClick = () => {
        this.close()
    }

    private _startPoint: { x: number, y: number } | undefined
    private _translatePoint: { x: number, y: number } | undefined
    private _documentBounds = { width: 0, height: 0 }
    private _dialogBounds: { x: number, y: number, width: number, height: number } | undefined
    private calcOffset(screenX: number, screenY: number) {
        if (!this._startPoint || !this._content || !this._dialogBounds || !this._documentBounds) return { x: 0, y: 0 }

        const offset = {
            x: screenX - this._startPoint.x,
            y: screenY - this._startPoint.y
        }

        if (this._dialogBounds.x + offset.x < 0)
            offset.x = -this._dialogBounds.x

        if (this._dialogBounds.y + offset.y < 0)
            offset.y = -this._dialogBounds.y

        if (this._dialogBounds.x + offset.x > this._documentBounds.width - this._dialogBounds.width)
            offset.x = -this._dialogBounds.x + this._documentBounds.width - this._dialogBounds.width

        if (this._dialogBounds.y + offset.y > this._documentBounds.height - this._dialogBounds.height)
            offset.y = -this._dialogBounds.y + this._documentBounds.height - this._dialogBounds.height

        return offset
    }
    private handleTitlePointerMove = (e: PointerEvent) => {
        if (!this._startPoint || !this._content || !this._dialogBounds) return

        const offset = this.calcOffset(e.screenX, e.screenY)
        this._content.style.transform = `translate(${offset.x}px,${offset.y}px)`
    }
    private handleTitlePointerDown = (e: PointerEvent) => {
        if (e.target !== this._titleBar) return

        this._startPoint = { x: e.screenX - (this._translatePoint?.x ?? 0), y: e.screenY - (this._translatePoint?.y || 0) }
        if (this._content && this._titleBar && this.element) {
            this._titleBar.style.cursor = "move"
            this._content.style.transition = "none"
            this._titleBar.setPointerCapture(e.pointerId)
            let dlgRect = this.element.getBoundingClientRect()
            this._documentBounds = { height: dlgRect.height, width: dlgRect.width }
            dlgRect = this._content?.getBoundingClientRect()
            this._dialogBounds = { x: dlgRect.x - (this._translatePoint?.x ?? 0), y: dlgRect.y - (this._translatePoint?.y || 0), width: dlgRect.width, height: dlgRect.height }
            e.preventDefault()
            e.stopImmediatePropagation()
        }
    }
    private handleTitlePointerUp = (e: PointerEvent) => {
        if (!this._startPoint || !this._titleBar || !this._content) return
        if (!this._translatePoint) this._translatePoint = { x: 0, y: 0 }

        const offset = this.calcOffset(e.screenX, e.screenY)
        this._translatePoint = offset
        this._content.style.transform = `translate(${this._translatePoint.x}px,${this._translatePoint.y}px)`
        this._startPoint = undefined
        this._content.style.transition = ""
        this._titleBar.style.cursor = ""
        this._titleBar?.releasePointerCapture(e.pointerId)
    }

    protected override createBody(content: HTMLElement) {
        const titleBar = this._titleBar = document.createElement("div")
        titleBar.style.maxHeight = "fit-content"
        titleBar.style.display = "flex"
        titleBar.style.flexDirection = "row"
        titleBar.style.alignItems = "center"
        titleBar.className = "dialog-window-title dialog-window-title"
        titleBar.onpointermove = this.handleTitlePointerMove;
        titleBar.onpointerdown = this.handleTitlePointerDown;
        titleBar.onpointerup = this.handleTitlePointerUp;

        const titleText = document.createElement("div")
        const titleCloseBtn = document.createElement("button")
        titleCloseBtn.innerHTML = "<i class='far fa-times' style='pointer-events:none'></i>"

        titleText.style.pointerEvents = "none"
        titleText.innerText = this._title || ""
        titleCloseBtn.onclick = this.handleCloseClick

        titleBar.appendChild(titleText)
        titleBar.appendChild(titleCloseBtn)

        const body = document.createElement("div")
        body.className = "dialog-window-body"
        body.style.display = "flex"
        body.style.flexDirection = "column"

        content.style.display = "flex"
        content.style.flexDirection = "column"

        content.appendChild(titleBar)
        content.appendChild(body)

        return body
    }

    override show(element: HTMLElement, options?: DialogOptions & { title?: string }) {
        this._title = options?.title
        return super.show(element, options)
    }
}

export function getTopOverlayElement() {
    return dropDownStack.length ? dropDownStack[dropDownStack.length - 1].element : undefined
}

export type DialogWindowCommand = {
    caption: string,
    id?: any,
    visible?: boolean,
    callback?: () => void
}
export type CommandAttributes = {
    visible?: boolean
}

export class CommandDialogWindow extends DialogWindow {
    private _commands?: DialogWindowCommand[]
    private _commandBarStyle?: string
    private _commandBarEl?: HTMLElement
    setCommandAttr(id: string, attributes: CommandAttributes) {
        const command = this._commands?.find(e => e.id === id)
        if (command) {
            command.visible = attributes.visible
            this.refreshCommands()
        }
    }
    refreshCommands() {
        if (!this._commands || !this._commandBarEl) return
        this._commandBarEl.innerHTML = ''
        for (const command of this._commands) {
            const commandBtn = document.createElement("div")
            commandBtn.hidden = !(command.visible ?? true)
            commandBtn.className = "dialog-window_command"
            commandBtn.innerHTML = command.caption;
            (commandBtn as any).__command = command
            commandBtn.onclick = this.handleCommandClick
            this._commandBarEl.appendChild(commandBtn)
        }
    }
    protected handleCommandClick = async (e: Event) => {
        if (!(e.target instanceof HTMLElement)) return

        const command = (e.target as any).__command
        if (command && command.callback)
            try {
                await command.callback()
            } catch (e: any) {
                millennium.browser.notify(e, "danger")
                throw e
            }
        else
            this.close(command?.id)
    }
    protected override createBody(content: HTMLElement) {
        const body = super.createBody(content)

        if (this._commands) {
            const commandBar = this._commandBarEl = document.createElement("div")
            commandBar.style.maxHeight = "fit-content"
            commandBar.className = "dialog-window_command-bar"

            if (this._commandBarStyle === "clear")
                commandBar.classList.add("dialog-window_command-bar_clear")

            this.refreshCommands()

            content.appendChild(commandBar)
        }

        return body
    }
    override show(element: HTMLElement, options?: DialogOptions & { title?: string, commands: DialogWindowCommand[], commandBarStyle?: "" | "clear" }) {
        this._commandBarStyle = options?.commandBarStyle
        this._commands = options?.commands
        return super.show(element, options)
    }
}