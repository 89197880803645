class StatusIndicator {
    private _timer?: any;
    constructor(public hostElement: HTMLElement, public visible: boolean) {
        if (visible)
            this.render();
    }
    render() {
        if (this._timer)
            clearTimeout(this._timer);
        $(this.hostElement).children('.status-indicator').remove();
        $(this.hostElement).first().css("cursor", "auto");

        if (this.visible) {
            $(this.hostElement).first().css("cursor", "progress");
            this._timer = setTimeout(() => {
                let loaderElement = $(`<div class= "status-indicator" style = "z-index:1000;display:none;position:absolute;background:rgba(0,0,0,0.4);align-items:center;justify-content:center;left:0;top:0;right:0;bottom:0" >
    <div class="windows8" id = "preloader" style = "display:flex;max-width:40px;max-height:40px" >
    <div class="wBall" id = "wBall_1" > <div class="wInnerBall" > </div></div>
    <div class="wBall" id = "wBall_2" > <div class="wInnerBall" > </div></div>
    <div class="wBall" id = "wBall_3" > <div class="wInnerBall" > </div></div>
    <div class="wBall" id = "wBall_4" > <div class="wInnerBall" > </div></div>
    <div class="wBall" id = "wBall_5" > <div class="wInnerBall" > </div></div>
    </div></div > `).appendTo(this.hostElement);

                loaderElement.first().css("display", "flex");
            }, 600);
        }
    }
    show() {
        this.visible = true;
        this.render();
    }
    hide() {
        this.visible = false;
        this.render();
    }
    destroy() {
        $(this.hostElement).first().css("cursor", "auto");
        if (this._timer)
            clearTimeout(this._timer);
        $(this.hostElement).children('.status-indicator').remove();
    }
}
export async function LoadIndicator<T>(element: HTMLElement, promise: Promise<T>): Promise<T> {
    const statusIndicator = new StatusIndicator(element, true);
    let result: T;
    try {
        result = await promise;
    }
    finally {
        statusIndicator.destroy();
    }
    return result;
}
