import "./millennium.browser.scss"
import { Client, Session, EventEmitter, getDefaultSession } from "lib/wts.client"
import { IMillenniumBrowser, IMillenniumFrame, IRoute, ICommand, FeedBackData, LazyLoadFacet, MsgBoxFlags, MsgBoxResult, IMillenniumNativeFrame, IModalWindow, IModalWindowOptions } from 'lib/millennium.core'
import { loadModule, loadExtension } from "../millennium.modules"
import { CommandDialogWindow, DialogWindowCommand, getTopOverlayElement } from "lib/wts.ui.overlays"
import { LoadIndicator } from "lib/wts.ui.indicators"
import { Md5 } from 'ts-md5/dist/md5'

class NativeFrame implements IMillenniumNativeFrame {
    resize(left: number, top: number, width: number, height: number, visible: number): void {
        return this.browser.resizeNativeFrame(this.handle, left, top, width, height, visible)
    }
    navigate(place: string): void {
        return this.browser.navigateNativeFrame(this.handle, place)
    }
    destroy(): void {
        return this.browser.destroyNativeFrame(this.handle)
    }
    constructor(private browser: MillenniumBrowser, private handle: number) {

    }
}

class MlnActivityIndicator extends HTMLElement {
    connectedCallback() {
        this.innerHTML =  /*html*/`        
        <div class="pos-activity-panel flex-col flex-align-center" style="margin:auto">
                <div class="windows8" style="width:50px;height:50px;position:relative">
                    <div class="wBall" id="wBall_1">
                        <div class="wInnerBall">
                        </div>
                    </div>
                    <div class="wBall" id="wBall_2">
                        <div class="wInnerBall">
                        </div>
                    </div>
                    <div class="wBall" id="wBall_3">
                        <div class="wInnerBall">
                        </div>
                    </div>
                    <div class="wBall" id="wBall_4">
                        <div class="wInnerBall">
                        </div>
                    </div>
                    <div class="wBall" id="wBall_5">
                        <div class="wInnerBall">
                        </div>
                    </div>
                </div>
                <div class="pos-activity-panel-message" style="margin-top:5px;text-align:center;font-size:120%;color:white"></div>
            </div>
        `
        this._messageEl = this.querySelector<HTMLElement>(".pos-activity-panel-message")
        this.style.position = "absolute"
        this.style.left = "0"
        this.style.top = "0"
        this.style.right = "0"
        this.style.bottom = "0"
        this.style.background = "rgba(0,0,0,0.5)"
        this.style.zIndex = "1000"
        this.style.display = this._active ? "flex" : "none"
    }
    private _messageEl: HTMLElement | undefined | null
    private _active = false
    private _timeout: any
    private _refCount = 0
    private _caption = ""
    set active(value: boolean) {
        if (this._active === value) return
        this._active = value
        const element = this.querySelector<HTMLElement>(".pos-activity-panel")
        this._active ? element?.removeAttribute("hidden") : element?.setAttribute("hidden", "")

        clearTimeout(this._timeout)
        this._timeout = setTimeout(() => {
            this.style.display = this._active ? "flex" : "none"
        }, value ? 500 : 50)
    }
    get active() {
        return this._active
    }
    begin() {
        this._refCount++
        if (this._refCount === 1) this.active = true
    }
    end() {
        this._refCount--
        if (this._refCount <= 0) this.active = false
    }
    get caption() {
        return this._caption
    }
    set caption(value: string) {
        this._caption = value
        if (this._messageEl) this._messageEl.innerText = value
    }
}

customElements.define("mln-activity-indicator", MlnActivityIndicator)
declare global {
    interface HTMLElementTagNameMap {
        "mln-activity-indicator": MlnActivityIndicator
    }
}

export class MillenniumBrowser implements IMillenniumBrowser {
    getVersionInfo(): PromiseLike<string> {
        throw new Error("Method not implemented.")
    }
    session = getDefaultSession();
    get onAuthFail(): EventEmitter { return this.session.onAuthFail }
    get onLoginDone(): EventEmitter { return this.session.onLoginDone }

    lastNotificationTime?: number
    lastNotificationMsg?: string
    isEmbedded = false;
    win_activate() {
        const module = $(".mln-content-frame.visible").data("mln-module-instance")
        if (module) void module.activate?.()
    }
    win_notify(message: string, type: "warning" | "danger" | "success") {
        $('.top-right').notify({
            message: { html: message },
            type: type,
            fadeOut: {
                delay: 5000
            }
        }).show()
    }
    notify(message: string | Error, type: "warning" | "danger" | "success") {
        let msgText = typeof message === "string" ? message : message.message
        if (msgText.startsWith("Remote Call Error")) {
            console.error(msgText)
            msgText = msgText.slice(msgText.indexOf(String.fromCharCode(13)) + 2)
        }

        if (Date.now() - (this.lastNotificationTime ?? 0) > 5000 || this.lastNotificationMsg !== msgText) {
            this.lastNotificationMsg = msgText
            this.lastNotificationTime = Date.now()
            this.win_notify(msgText, type)
        }
    }
    async switchUser(): Promise<void> {
        return new Promise(async (resolve, reject) => {

            const defSession = this.session
            await defSession.logout(true)

            this.onAuthFail.trigger({
                blank: true,
                message: "Entre com um usuário do grupo " + defSession.userGroup,
                callback: async (host: string, username: string, password: string) => {

                    const session = new Session()
                    await session.login(this.session.appName, host || this.session.host, username, password)
                    if (session.userGroup !== defSession.userGroup) {
                        await session.logout()
                        throw Error("O usuário deve ser do grupo " + defSession.userGroup)
                    }

                    session.onAuthFail.assign(defSession.onAuthFail)
                    session.onLoginDone.assign(defSession.onLoginDone)
                    session.isDefault = true
                    this.session = session

                    defSession.destroy()

                    this.loginDone()
                    resolve()
                }
            })
        })
    }
    msgBox(message: string, title: string, flags: number): Promise<number> {
        const dialog = new CommandDialogWindow()

        const msg = document.createElement("div")
        msg.innerText = message
        msg.style.textAlign = "center"
        msg.style.padding = "30px"
        msg.style.fontSize = "110%"

        let commands: DialogWindowCommand[] = []

        if (flags & MsgBoxFlags.MB_OK || flags === 0)
            commands = [{ caption: "OK", id: MsgBoxResult.IDOK }]
        else if (flags & MsgBoxFlags.MB_OKCANCEL)
            commands = [{ caption: "OK", id: MsgBoxResult.IDOK }, { caption: "Cancelar", id: MsgBoxResult.IDCANCEL }]
        else if (flags & MsgBoxFlags.MB_YESNO)
            commands = [{ caption: "Sim", id: MsgBoxResult.IDYES }, { caption: "Não", id: MsgBoxResult.IDNO }]
        else if (flags & MsgBoxFlags.MB_YESNOCANCEL)
            commands = [{ caption: "Sim", id: MsgBoxResult.IDYES }, { caption: "Não", id: MsgBoxResult.IDNO }, { caption: "Cancelar", id: MsgBoxResult.IDCANCEL }]
        else if (flags & MsgBoxFlags.MB_RETRYCANCEL)
            commands = [{ caption: "Sim", id: MsgBoxResult.IDYES }, { caption: "Retentar", id: MsgBoxResult.IDRETRY }, { caption: "Cancelar", id: MsgBoxResult.IDCANCEL }]

        return dialog.show(msg, {
            title,
            commands,
            width: "fit-content",
            height: "fit-content",
            minWidth: "400px",
            minHeight: "0",
            commandBarStyle: "clear"
        })
    }
    //frame callbacks
    frame_navigateSc = (frame: IMillenniumFrame | undefined, key: string, data: string, vars: Object, caption?: any) => {
        return this.load(undefined/*force new tab*/, key, undefined, vars)
    };
    frame_navigate = (frame: IMillenniumFrame | undefined, data: string, vars: Object) => {
        return this.load(undefined/*force new tab*/, "", data, vars)
    };
    frame_close = (frame: IMillenniumFrame) => { };
    frame_setCaption = (frame: IMillenniumFrame, value: string) => { };
    frame_setUrl = (frame: IMillenniumFrame, value: string) => { };
    frame_activity = (frame: IMillenniumFrame, value: string) => { };
    frame_activate = (frame: IMillenniumFrame) => { };
    frame_notify = (frame: IMillenniumFrame, message: string, type: string) => { };
    frame_initialized = () => { };
    frame_create: () => IMillenniumFrame = () => new MillenniumFrame(this);
    createFrame(): IMillenniumFrame {
        return this.frame_create()
    }
    async createFrameAsNative() {
        const handle = await this.createNativeFrame()
        return new NativeFrame(this, handle)
    }
    private rootRoutes?: IRoute[]
    async getVersion() {
        if (this.isEmbedded)
            return {}
        else {
            return {
                "millennium": await this.getVersionInfoMillennium(),
                "omni": await this.getVersionInfoOmnichannel()
            }
        }
    }
    async getObjectActions(objectName: string): Promise<ICommand[]> {
        //todo: read from api
        return []
    }
    async getRoutes(session: Session = this.session) {
        let routes: (IRoute & {
            id: string,
            appname?: string
        })[] = []

        try {
            routes = await Client.getJSON<any>("./millennium.routes.json")
        }
        catch { }

        const acl = await Client.getJSON<(IRoute & {
            id: string
        })[]>(session.host + "/api/routes", { appname: session.appName, moduleType: "html" }, session)

        if (routes) {

            const clientRoutes = routes.filter(e => e.appname === session.appName).map(e => {
                return { ...e, id: e.urlKey, key: e.urlKey, _client: true, _preloaded: true }
            })

            const adjustedRoutes = []
            for (let route of routes) {
                if (acl.findIndex(e => e.key === route.urlKey) >= 0 || route.urlKey === "start") {
                    route["id"] = route.urlKey
                    route["key"] = route.urlKey;
                    (route as any)["_preloaded"] = true
                    adjustedRoutes.push(route)
                }
            }
            routes = [...clientRoutes, ...(adjustedRoutes.length ? adjustedRoutes : acl)]
        }
        else
            routes = acl

        this.rootRoutes = routes
        return routes
    }
    async getRoute(key: string, data?: string, session: Session = this.session): Promise<IRoute> {

        if (key.startsWith("$"))
            return {
                target: key.slice(1).toLocaleLowerCase(),
                text: "",
                urlKey: key,
                icon: ""
            }

        let cachedRoute: IRoute | undefined
        if (session === this.session && this.rootRoutes) {
            cachedRoute = this.rootRoutes.find(e => (e.urlKey || e.key || "").toLowerCase() === (key || data || "").toLowerCase())
            if (cachedRoute && cachedRoute.target)
                return Promise.resolve(cachedRoute)
        }
        const route = await Client.getJSON<IRoute>(session.host + "/api/routes", { key: key || "", data: data || "" }, session)
        if (!cachedRoute && this.rootRoutes) {
            cachedRoute = { key } as IRoute
            this.rootRoutes.push(cachedRoute)
        }
        for (let k in route)
            (cachedRoute as any)[k] = (route as any)[k]

        if (cachedRoute && cachedRoute.target === "")
            cachedRoute.target = "_"

        if (key === "" && cachedRoute)
            cachedRoute.urlKey = data || ""

        return route
    }
    get inLogin() {
        return this.session.inLogin
    }
    showUserInfo() {
        function getGravatarImage(email: string, args?: any) {
            args = args || ""
            var BASE_URL = "https://www.gravatar.com/avatar/"
            return (BASE_URL + ((email) ? (Md5.hashStr(email.toLowerCase().trim()) + "?") : "?d=mm&") + args).trim()
        }
        if (!this.isEmbedded) {
            try {
                $("#usr-img").attr("src", getGravatarImage(this.session.getLoggedUserInfo().employeeEmail || this.session.getLoggedUserInfo().employeeName, "s=28"))
            } catch (error: any) {
                this.notify(error, "warning")
            }
            $("#employee-name").text(this.userName)
        }
    }
    loginDone() {
        this.session.isDefault = true
        this.showUserInfo()
        this.session.loginDone()
        if (this.onLoginDone)
            this.onLoginDone.trigger()
    }
    async login(appName: string, host: string, userName: string, passWord: string) {
        const sessionData = await this.session.login(appName, host, userName, passWord)

        this.loginDone()

        return sessionData
    }

    accessList = new Map<string, Set<string>>();
    async hasAccess(key: string, session?: Session): Promise<boolean> {
        if (!session) session = getDefaultSession()

        const mapKey = `${key}`
        let accessList = this.accessList.get(session.id)

        if (!accessList) {

            //don´t filter appname, because some permitions are shared between apps (ag: omni & millennium)
            const aclArray = await Client.getJSON<string[] | { key: string }[]>(this.session.host + "/api/routes", { grants: "true", appname: "" }, session)

            accessList = new Set()
            for (const item of aclArray) {
                if (typeof item === "string") {
                    accessList.add(item)
                }
                else
                    accessList.add(item.key)
            }

            this.accessList.set(session.id, accessList)
        }

        return accessList.has(mapKey)
    }

    async accessPrompt(message: string, key: string): Promise<boolean> {
        const access = await this.hasAccess(key)
        if (access)
            return access
        else {
            const { host, username, password } = await new Promise<{ host: string, username: string, password: string }>((resolve, _reject) => {
                this.onAuthFail.trigger({
                    blank: true,
                    message: message,
                    callback: (host: string, username: string, password: string) => {
                        resolve({ host, username, password })
                        this.onLoginDone.trigger()
                    }
                })
            })

            const session = new Session()
            await session.login(this.session.appName, host || this.session.host, username, password)
            const hasAccess = await this.hasAccess(key, session)
            try {
                await session.logout()
            } catch { }
            session.destroy()

            return hasAccess
        }
    }
    async logout() {
        if (this.session.isActive())
            await this.session.logout()
    }
    async decodeRoute(routeKey: string, routeObj: any, vars?: any): Promise<IRoute> {
        if (!routeKey) {
            routeObj = await this.getRoute(routeKey, routeObj)
        } else if (routeKey && !routeObj)
            routeObj = await this.getRoute(routeKey)
        else {
            if ((routeObj as any)["_preloaded"]) {
                const route = await this.getRoute(routeKey)
                routeObj = { ...routeObj, ...route }
            } else
                routeObj = await this.getRoute(routeKey)
        }

        if (vars && routeObj.params) {
            const params = []
            for (const param of routeObj.params) {
                const item = { ...param }
                for (let key in item)
                    if (typeof item[key] === "string" && item[key].startsWith("{$")) {
                        let varName = item[key].trim().slice(2, -1)
                        if (varName.endsWith("[]")) varName = varName.slice(0, -2)
                        item[key] = vars[varName]
                    }

                params.push(item)
            }
            routeObj = { ...routeObj, params }
        }

        return routeObj
    }
    async getVersionInfoOmnichannel(): Promise<string> {
        try {
            const value = await Client.getJSON<any>("chcp.json")
            return value && value.release
        } catch {
            return ""
        }
    }
    async getVersionInfoMillennium(): Promise<string> {
        try {
            const text = await Client.getText(window.location.protocol + "//" + window.location.host + "/files/apps/installedpacks/{8AD628CF-4B83-4A72-8A50-C6835E946B52}.inf")
            return text.split('\r\n').filter(e => e.startsWith("Version"))[0].split("=")[1]
        } catch {
            return ""
        }
    }
    afterLoad?: () => void
    async getSessionId(): Promise<string> {
        return this.session?.id
    }
    navigate(place: string, vars: Object): Promise<void> {
        return this.frame_navigate(undefined, place, vars)
    }
    navigateSc(key: string, data: string, vars: Object, caption?: any): Promise<void> {
        return this.frame_navigateSc(undefined, key, data, vars, caption)
    };
    async load(frame: IMillenniumFrame | undefined, routeKey: string, route?: IRoute | string, vars?: any): Promise<any> {
        return LoadIndicator(document.body, this.internalLoad(frame, routeKey, route, vars))
    }
    async internalLoad(frame: IMillenniumFrame | undefined, routeKey: string, route?: IRoute | string, vars?: any): Promise<any> {
        try {
            try {

                let params: any
                let url = ""
                if ((typeof route === "object")) {//the route came ready from the host, like in embeded use case
                    params = route
                    routeKey = route?.target || route?.urlKey || ""
                } else {//here we just have the key, need to load route details
                    url = window.location.pathname + "#/" + (routeKey || route || "").toLowerCase() + ((vars instanceof Object) ? "/" + JSON.stringify(vars) : "")
                    params = await this.decodeRoute(routeKey, route, vars)
                }

                let frameLocation = ""

                var moduleName = params.target

                if (!moduleName) return

                if (moduleName.indexOf(",") > 0)
                    [frameLocation, moduleName] = moduleName.split(",")
                if (moduleName.substr(-5).toLowerCase() === ".html")
                    moduleName = moduleName.slice(0, -5)
                if (moduleName.charAt(0) === '#')
                    moduleName = moduleName.slice(1)

                if ((frameLocation === "_blank" || !frame) && frame?.element.childElementCount !== 0)
                    frame = this.frame_create()
                //module loader
                $(frame.element).attr("id", params.target)

                const validKeys = ["caption"]
                const paramCaption = (params?.params as any[])?.find(e => validKeys.indexOf(e["@type"]) !== -1)
                const caption = params?.caption || params?.text || paramCaption?.caption || ""
                frame.setCaption(caption)
                frame.setUrl(url)

                let { moduleClass, htmlTemplate, cssTemplate } = await loadModule(moduleName)

                if (cssTemplate && typeof cssTemplate !== "object") {
                    let tmpStyle = $("<div>").append("<style>")
                    tmpStyle.children().text(cssTemplate.replace(/\n/g, ''))
                    htmlTemplate = "<style>" + cssTemplate + $(tmpStyle).children(".css-finalized").text() + "</style>" + htmlTemplate
                }

                var module = (moduleClass.default) ? new moduleClass.default() : new moduleClass()

                try {
                    $(frame.element).data("mln-frame-instance", frame)
                    if (!module.bind) {
                        $(htmlTemplate).appendTo(frame.element)
                        if (module.onInit)
                            await module.onInit(frame, params)
                    }
                    else
                        await module.bind(frame, params, htmlTemplate)

                } catch (e: any) {
                    this.notify("Erro carregando módulo: " + ((e instanceof Error) ? e.message : e.toString()), "danger")
                    if (e instanceof Error) console.error(e.message, e.stack)
                    return
                }

                $(frame.element).data("mln-module-instance", module)

                await frame.activate()
                window.dispatchEvent(new Event("resize"))

                $(frame.element).addClass("activated")

                //only ok after session joint
                this.frame_activate(frame)
            } finally {
                if (this.afterLoad)
                    this.afterLoad()
            }
        } catch (error: any) {
            this.notify(error, "danger")
            frame?.close()
        }
    }

    get userName(): string {
        return this.session.userName
    }
    get userGroup(): string {
        return this.session.userGroup
    }
    get wsid(): string {
        return this.session.wsid
    }

    async joinSession(host: string, sessionId: string, sessionData: any, joinOnServer: boolean, appName?: string) {
        await this.session.joinSession(host, sessionId, sessionData, joinOnServer, appName)
        this.loginDone()
    }

    loginError() {
        this.notify("Não autenticado", "danger")
    }
    getData(dataType: string, callback: (data: string) => void) {
        callback('')
    }
    private handleWindowMessage = (e: MessageEvent) => {
        if (e.origin !== location.origin) return
        this.broadcastRecv(e.data.name, e.data.data)
    }
    private _subscriptions = new Map<string, { callbacks: any[], id: number }>();
    broadcastRecv = (subject: string, data: string) => {
        const subs = this._subscriptions.get(subject)
        if (subs)
            for (const sub of subs.callbacks)
                sub(JSON.parse(data))
    }
    broadcast(name: string, data: any) {
        window.postMessage({ name, data: JSON.stringify(data) }, location.origin)
    }
    subscribeRemove(subject: string, callback: (data: any) => void) {
        let subs = this._subscriptions.get(subject.toUpperCase())
        if (subs) {
            const idx = subs.callbacks.findIndex(e => e === callback)
            if (idx >= 0) subs.callbacks.splice(idx, 1)
            if (subs.callbacks.length === 0) {
                this.win_subscribeRemove(subject, subs.id)
                this._subscriptions.delete(subject)
            }
        }
    }
    subscribeAdd(subject: string, callback: (data: any) => void) {
        let subs = this._subscriptions.get(subject.toUpperCase())
        if (!subs) {
            const subHandle = this.win_subscribeAdd(subject.toUpperCase(), this.broadcastRecv)
            subs = { callbacks: [], id: subHandle }
            this._subscriptions.set(subject, subs)
        }
        subs.callbacks.push(callback)
    }
    private win_subscribeAdd(subject: string, callback: (subject: string, data: any) => void): number {
        return -1
    }
    private win_subscribeRemove(subject: string, handle: number) {
    }
    initialize() {
    }
    printPDF(pdfAsString: string, printerName?: string, title?: string): void {
        throw Error("printPDF not supported")
    }
    async loadExtension(name: string) {
        return loadExtension(name + (!name.startsWith("http") ? ".js" : ""))
    }
    constructor() {
        window.addEventListener("message", this.handleWindowMessage)
    }
    createModal(content: HTMLElement, options: IModalWindowOptions): IModalWindow {
        const dialog = new CommandDialogWindow()
        return {
            close: () => dialog.close(),
            show: () => dialog.show(content, options)
        }
    }
    destroy() {
        window.removeEventListener("message", this.handleWindowMessage)
    }
    createNativeFrame(): Promise<number> {
        throw Error("not implemented")
    };
    resizeNativeFrame(frameHandle: number, left: number, top: number, width: number, height: number, visible: number): void {
        throw Error("not implemented")
    };
    navigateNativeFrame(frameHandle: number, place: string): void {
        throw Error("not implemented")
    };
    destroyNativeFrame(frameHandle: number): void {
        throw Error("not implemented")
    };
}

export class MillenniumFrame implements IMillenniumFrame {
    private _isProcessing: boolean = false;
    private _bodyElement: HTMLElement
    private _tabElement: HTMLElement
    private _navElement: HTMLElement
    constructor(public readonly browser: MillenniumBrowser) {
        //create tab on the top navbar
        $("#header").find("ul").children().removeClass("active")
        let newTab = $("<li class='active'><a href='' style='float:left'></a><i class='fa fa-times nav-btn-close' style='margin-left:auto'></span></li>")
        $("#header").find("ul").append(newTab)

        //create tab on the left navbar
        $("#header-left").find("ul").children().removeClass("active")
        let newTabLeft = $("<li class='active' style='width:100%;float:left'><a href='' style='float:left'></a><i class='fa fa-times nav-btn-close' style='margin-left:auto;font-size:0.8em'></i></li>")
        $("#header-left").find("ul").append(newTabLeft)

        let newSheet = $("<div class='mln-content-frame'></div>")

        $("#content-frame").append(newSheet)

        newTabLeft.on("click", this.handleTabClick)
        newTab.on("click", this.handleTabClick)

        newTab.data("mln-tab-sheet", newSheet)
        newTabLeft.data("mln-tab-sheet", newSheet)

        this._bodyElement = newSheet[0]
        this._tabElement = newTab[0]
        this._navElement = newTabLeft[0]
    }
    requestFullScreen() {
        $('.navbar').hide()
    }
    async switchUser(): Promise<void> {

    }
    async accessPrompt(message: string, key: string) {
        return this.browser.accessPrompt(message, key)
    }
    async hasAccess(key: string | string[]) {
        if (typeof key === "string") {
            const access = await this.browser.hasAccess(key)
            return access
        } else {
            const result: any = {}
            for (const item of key)
                result[item] = (await this.browser.hasAccess(item))
            return result
        }
    }
    private async closeTab(tabElement: HTMLElement) {
        let tabEl = $(tabElement)

        let lasttab = tabEl.prev()
        if (lasttab.length === 0) lasttab = tabEl.next()

        let wasActive = tabEl.has("active")
        let module = tabEl.data("mln-tab-sheet").data("mln-module-instance")
        if (module) {
            if (module.OnDestroy)
                await module.OnDestroy()
            else if (module.destroy)
                await module.destroy()
            module.frame = null
        }
        tabEl.data("mln-tab-sheet").data("mln-module-instance", null)
        tabEl.data("mln-tab-sheet").remove()
        tabEl.remove()

        if (lasttab.length > 0 && wasActive) {
            lasttab.click()
        } else if (!millennium.browser.isEmbedded)
            history.pushState("", document.title, window.location.pathname)
    }
    private async activateTab(tabElement: HTMLElement) {
        let tabEl = $(tabElement).closest("li")
        tabEl.closest("ul").children().not(tabEl).removeClass("active")
        $("#content-frame").children().not(tabEl).attr("hidden", "true")
        tabEl.addClass("active")
        tabEl.data("mln-tab-sheet").addClass("visible")
        tabEl.data("mln-tab-sheet").removeAttr("hidden")
        const module = tabEl.data("mln-tab-sheet").data("mln-module-instance")
        if (module && module.activate) await module.activate()
        if (!millennium.browser.isEmbedded)
            history.pushState(null, "", (tabElement as HTMLLinkElement).href.substring((tabElement as HTMLLinkElement).href.indexOf("#")))
    }
    private async closeTabs() {
        const navBtnClose = this._navElement.querySelector(".nav-btn-close")
        const tabBtnClose = this._tabElement.querySelector(".nav-btn-close")
        navBtnClose && navBtnClose.parentElement && await this.closeTab(navBtnClose.parentElement)
        tabBtnClose && tabBtnClose.parentElement && await this.closeTab(tabBtnClose.parentElement)
        this._bodyElement.remove()
    }
    private async activateTabs() {
        const navLink = this._navElement.querySelector("a")
        const tabLink = this._tabElement.querySelector("a")
        navLink && await this.activateTab(navLink)
        tabLink && await this.activateTab(tabLink)
        this._tabElement.classList.add("visible")
        window.dispatchEvent(new Event("resize"))
    }
    handleTabClick = async (e: Event) => {
        if (!e.target) return
        e.preventDefault()

        if ($(e.target).hasClass("nav-btn-close"))
            await this.closeTabs()
        else
            await this.activateTabs()
    }
    navigateSc(key: string, data: string, vars: Object, caption?: any) {
        return this.browser.frame_navigateSc(this, key, data, vars, caption)
    };
    navigate(place: string, vars?: Object) {
        return this.browser.frame_navigate(this, place, vars || {})
    };
    close = () => {
        this.closeTabs()
        this.browser.frame_close(this)
    };
    setCaption(value: string) {
        const tabLink = this._tabElement.querySelector("a")
        const navLink = this._navElement.querySelector("a")
        if (tabLink) tabLink.text = value
        if (navLink) navLink.text = value
        this.browser.frame_setCaption(this, value)
    };
    setUrl(value: string) {
        const tabLink = this._tabElement.querySelector("a")
        const navLink = this._navElement.querySelector("a")
        if (tabLink) tabLink.href = value
        if (navLink) navLink.href = value
        this.browser.frame_setUrl(this, value)
    };
    activate() {
        this.activateTabs()
        this.browser.frame_activate(this)
    };
    get element(): HTMLElement {
        return this._bodyElement
    };
    set isProcessing(value: boolean) {
        this._isProcessing = value
    };
    get isProcessing() {
        return this._isProcessing
    }

    getActivityElement(element?: HTMLElement): MlnActivityIndicator | undefined {
        return (element ?? this._bodyElement).querySelector(":scope > mln-activity-indicator") as MlnActivityIndicator | undefined
    }
    beginProcessing(element?: HTMLElement): MlnActivityIndicator {

        const parentEl = element ?? this._bodyElement
        let activityEl = parentEl.querySelector(":scope > mln-activity-indicator") as MlnActivityIndicator | undefined

        if (!activityEl) {
            activityEl = document.createElement("mln-activity-indicator")
            parentEl.appendChild(activityEl)
        }

        activityEl.begin()

        return activityEl
    }
    endProcessing(error?: string, element?: HTMLElement) {
        if (error) this.browser.notify(error, "warning")
        this.getActivityElement(element)?.end()
    }
    lazyLoad(lazyFacet: LazyLoadFacet, callback: () => void): void {
        if (lazyFacet.timer) clearInterval(lazyFacet.timer)
        lazyFacet.timer = setTimeout(() => {
            callback()
        }, lazyFacet.delay)
    }
    notify(message: string, type: "warning" | "danger" | "success") {
        this.browser.notify(message, type)
        this.browser.frame_notify(this, message, type)
    }
    activity<T extends PromiseLike<any>>(waiter: T, feedBackData?: FeedBackData, silent?: boolean): T {

        if (!waiter.then) return waiter

        let currentMessage = 1
        let messageTimer: any | undefined
        const targetEl = feedBackData?.element ?? getTopOverlayElement()
        const activityEl = this.beginProcessing(targetEl)

        const handleError = (error: any) => {
            this.endProcessing(silent ? undefined : error, targetEl)
            clearInterval(messageTimer)
            return Promise.reject(error) as any
        }

        try {
            if (feedBackData) {
                activityEl.caption = (feedBackData.waitMessages?.[0]) ?? ""
                if (feedBackData.waitMessages && feedBackData.estimatedTime)
                    messageTimer = setInterval(() => {
                        activityEl.caption = feedBackData.waitMessages?.[currentMessage] ?? ""
                        currentMessage++
                        if (currentMessage >= (feedBackData.waitMessages?.length ?? 0)) clearInterval(messageTimer)
                    }, feedBackData.estimatedTime / feedBackData.waitMessages.length)
            } else
                activityEl.caption = ""

            return waiter.then((result: any) => {
                this.endProcessing(undefined, targetEl)
                clearInterval(messageTimer)
                return result
            }, handleError) as any
        } catch (error: any) {
            return handleError(error)
        }
    }
    async loadExtension(name: string): Promise<any> {
        return this.browser.loadExtension(name)
    }
}